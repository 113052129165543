<template>
  <div class="vue-template">
    <form>
      <h3>{{ this.title }}</h3>
      <br>

      <iframe v-if="jitsiCode[0] != '_'" :src="jitsiUrl" allow="camera; microphone; fullscreen; display-capture;" frameborder="0"></iframe>

      <input type="button" v-if="jitsiCode == '_wait'" @click="acceptCall()" :value="'Accept'" class="btn btn-primar btn-accept">
      <input type="button" v-if="jitsiCode == '_wait'" @click="endCall()" :value="'Deny'" class="btn btn-primar btn-deny">

    </form>
  </div>
</template>


<script>
import {socialvue} from '@/config/pluginInit'
import api from "@/api/api";

export default {
  name: 'VideoCallCliPage',
  mounted() {
    socialvue.index();
  },
  data() {
    return {
      callId: this.$route.params.callId,
      jitsiCode: '_wait',
      customerName: this.$route.params.cusName,
      displayName: ''
    }
  },
  created() {

  },
  methods: {
    acceptCall() {
      api
        .post('calls/acceptCall', {
          token: this.token,
          call_id: this.callId
        })
        .then((response) => {
          this.customerName = response.data.call.from,
          this.displayName = response.data.call.to,
          this.jitsiCode = response.data.call.code
        });
    },
    endCall() {
      api
        .post('calls/denyCall', {
          token: this.token,
          call_id: this.callId
        })
        .then((response) => {
          this.jitsiCode = response.data.call.code
        });
    }
  },
  computed: {
    token() {
      return this.$store.getters["Setting/token"];
    },
    jitsiUrl() {
      return `https://meet.jit.si/${this.jitsiCode}#userInfo.displayName="${this.displayName}"&config.prejoinPageEnabled=false`
    },
    title() {
      if (this.jitsiCode == '_wait') {
        return `${this.customerName} is calling...`;
      }
      if (this.jitsiCode == '_deny') {
        return 'Call ended';
      }

      return `Call with ${this.customerName}`;
    }
  }
};
</script>

<style scoped>
input[type="button"] {

  color: white;
  width: 45%;
  height: 50px;
  margin-top: 20px;
}
iframe {
  width: 100%;
  height: 60vh;
}
.btn-accept {
  background-color: var(--success);
  float: left; 
}
.btn-deny {
  float: right;
  background-color: var(--danger);
}
</style>
